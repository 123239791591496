import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import { graphql, useStaticQuery } from "gatsby";
import { Img } from "../../classes/Img";
import { GatsbyImage } from "gatsby-plugin-image";
import { WmkLink } from "wmk-link";

const Wrap = styled.div`
*{
  }
  max-width: 10rem;
`;

/**
 *
 * @param {string} siteTitle
 * @returns {JSX}
 */
const DipraLogoWhite = () => {
  const logo = useStaticQuery(graphql`
    query {
      logo: contentfulAsset(title: { eq: "DIPRA Logo White" }) {
        title
        gatsbyImageData
        file {
          url
          contentType
        }
      }
    }
  `).logo;
  const logoImg = new Img(logo);
  return (
    <Wrap>
      <WmkLink to={`/`}>
        <GatsbyImage
          image={get(logoImg, `gatsby`)}
          alt={"Ducitle Iron Pipe Research Assocaition"}
        />
      </WmkLink>
    </Wrap>
  );
};

export default DipraLogoWhite;
