import * as React from "react";

/**
 * @component - SVG shape for use as SVG mask. Add state, variables,
 * and viewport detection to get the shape to scale as desired
 * @param {string} href - url to image that will be masked
 * @returns {JSX}
 */
const DipraArrow = ({ href }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="700"
      height="790.332"
      viewBox="0 0 700 790.332">
      <defs>
        <pattern
          id="pattern"
          width="1"
          height="1"
          viewBox="146.463 27.169 614.101 693.346">
          <image
            preserveAspectRatio="xMidYMid slice"
            width="800"
            height="500"
            href={href}
          />
        </pattern>
      </defs>
      <path
        id="Path_772"
        data-name="Path 772"
        d="M-382.177-64.383l-350.03-350.03-237.129,237.17-112.84,112.86h225.233l1.148,440.3,249.137-.02V-63.922Z"
        transform="translate(1082.178 414.414)"
        fill="url(#pattern)"
      />
    </svg>
  );
};

export default DipraArrow;
