import * as React from "react";
import DipraArrow from "../../images/svg/DipraArrow";
import DipraLogoWhite from "./DipraLogoWhite";
import styled from "styled-components";
import { colors } from "../../vars/palette";
import { useState, useEffect } from "react";
import { Type } from "./Typography";

const HeroImage = styled.div`
  * {
  }
  .hero-container {
    transition: all 0.3s ease;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
    background-color: #23d0f7;
    display: flex;
    flex-direction: row;
  }

  .hero-container-child {
    width: 100%;
    // height: 15.8rem;
    height: 22rem;
    overflow: hidden;
    background: ${colors.blue.hex};
    //position: fixed;
    display: flex;
    flex-direction: row;
    .container-fluid {
      padding: 2rem 0 0;
    }
  }

  .arrowImage {
    display: none;
  }

  .hero-text {
    color: ${colors.white.hex};
    padding-right: 1rem;
    padding-left: 2rem;
    h1 {
      font-weight: 400;
    }
    h4 {
      text-transform: uppercase;
    }
    h4,
    p {
      color: ${colors.grayBlue.hex};
    }
    p {
      font-size: 0.9375rem;
    }
    .body {
      max-width: 35vw !important;
    }
  }

  .dipraLogo {
    padding-right: 3rem;
    & > div {
      max-width: 23rem;
    }
  }

  .container-fluid {
    display: flex;
    flex-direction: row;
  }

  .getarrowImage {
    z-index: 2;
    //  position: absolute;
    animation: slide-up 2s;
  }

  .one {
    //  background: red;
    width: 15%;
  }

  .two {
    //  background: orange;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .three {
    margin-top: 3%;
    max-width: 20vw;
  }

  .logosArea {
    display: flex;
    flex-direction: row;
    just-content: flex-end;
    align-item: stretch;
  }

  sup {
    font-size: 20px;
    top: -1.3rem;
  }

  @keyframes slide-up {
    from {
      margin-top: 100%;
    }

    to {
      margin-top: 0%;
    }
  }

  @media screen and (min-width: 1440px) {
    .hero-container {
      height: 28rem;
    }
    .hero-container-child {
      height: 25rem;
    }
    .hero-text {
      // padding-left: 20vw;
      h4 {
        max-width: none !important;
      }
    }
    .one {
      width: 20vw;
      .logosArea {
        position: absolute;
        top: 4.5rem;
        left: -14rem;
        svg {
          transform: scale(1.5);
        }
      }
    }
    .three {
      width: 20vw;
      max-width: none;
      padding-left: 3rem;
    }
    .container-fluid {
      left: -8rem;
      padding: 2rem 2rem;
    }
  }

  @media screen and (max-width: 1440px) {
    .hero-text {
      .body {
        max-width: none !important;
        padding-right: 1rem;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    .hero-container {
      height: 30rem;
    }
    .hero-container-child {
      height: 27rem;
    }
    .logosArea {
      position: absolute;
      left: -17rem;
      top: 5rem;
    }
    .container-fluid {
      left: -3rem;
      padding: 2rem 0rem;
    }
    .one {
      width: 25%;
      .logosArea {
        top: 6.2rem;
        left: -20rem;
        svg {
          transform: scale(1.4);
        }
      }
    }
    .two {
      padding: 0 2rem 0 3rem;
    }
    .three {
      padding: 3rem 1rem 1rem 2rem;
      margin-top: 0%;
    }
    .dipraLogo {
      padding-right: 0rem;
    }
    .dipraLogo > div {
      max-width: 24rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .hero-container {
      height: 34rem;
    }
    .hero-container-child {
      height: 31rem;
    }
    .logosArea {
      position: absolute;
      left: -20rem;
      top: 9rem;
    }
    .container-fluid {
      left: 0rem;
      align-items: flex-start;
      padding: 2rem;
    }
    .one {
      width: 25%;
      .logosArea {
        top: 10rem;
        left: -25rem;
        svg {
          transform: scale(1.3);
        }
      }
    }
    .two {
      height: 100%;
      padding: 0 0 0 2rem;
    }
    .three {
      padding: 10vw 0rem 0rem 0;
    }
    .dipraLogo {
      padding: 0 2rem 0 0;
    }
    .dipraLogo > div {
      max-width: 24rem;
      width: 10rem;
    }
    .hero-text {
      color: ${colors.white.hex};
      padding-right: 1rem;
      h1 {
        font-weight: 400;
        font-size: 20px;
      }
      h4 {
        text-transform: uppercase;
      }
      h4,
      p {
        color: ${colors.grayBlue.hex};
      }
      p {
        padding-right: 2rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .hero-container {
      height: 34rem;
    }
    .hero-container-child {
      height: 32rem;
    }

    .container-fluid {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1 0 100%;
    }
    .logosArea {
      z-index: 10;
      position: absolute;
      top: 10rem;
      left: -24rem;
      display: flex;
      flex-direction: row;
    }
    .two {
      order: 1;
      padding: 2rem 1rem 0;
      position: relative;
      left: 10vw;
      h5 {
        font-size: 1rem !important;
      }
    }

    .one {
      order: 3;
      display: flex;
      flex-direction: row;
    }
    .three {
      order: 2;
      display: flex;
      justify-content: flex-end;
      align-self: normal;
      padding: 0rem;
      .dipraLogo {
        position: relative;
        right: -60vw;
        bottom: 3rem;
        .gatsby-image-wrapper {
          width: 30vw;
          img {
            width: 30vw;
            // height: 15vw;
          }
        }
      }
    }
    .dipraLogo > div {
      // max-width: 32vw;
    }

    .hero-text {
      padding: 1rem 0;
      .body {
        visibility: hidden;
      }
    }
  }
  @media screen and (max-width: 569px) {
    .dipraLogo {
      right: -70vw !important;
      bottom: 5rem !important;
    }
    .hero-text {
      max-width: 75vw;
      h4 {
        font-size: 7.5vw !important;
      }
    }
    sup {
      font-size: 1rem;
      top: -0.8rem;
    }
  }
`;
/**
 */

interface PageHeroProps {
  arrowUrl: string;
  headline: string;
  subhead: string;
  text: string;
}

const PageHero = ({ arrowUrl, headline, subhead, text }: PageHeroProps) => {
  const [arrowIn, setAarrowIn] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAarrowIn(true);
    }, 750);
    return () => clearTimeout(timeout);
  }, [arrowIn]);
  const poppedHeadline = headline.substring(0, headline.length - 1);
  const headlineRegTrademark = headline.substring(
    headline.length - 1,
    headline.length
  );
  return (
    <HeroImage>
      <div className="hero-container">
        <div className="hero-container-child">
          <div className="one">
            <div className="logosArea">
              <div className={arrowIn ? "getarrowImage" : "arrowImage"}>
                <div className="">
                  <DipraArrow href={arrowUrl} />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="three">
              <div className="dipraLogo" style={{ visibility: "hidden" }}>
                <DipraLogoWhite />
              </div>
            </div>
            <div className="two">
              <div className="hero-text">
                <Type.H2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    letterSpacing: "1.5px"
                  }}>
                  {subhead}
                </Type.H2>
                <Type.H1
                  style={{
                    color: colors.white.hex,
                    maxWidth: "31rem",
                    textTransform: "none"
                  }}>
                  {poppedHeadline}
                  <span>
                    <sup>{headlineRegTrademark}</sup>
                  </span>
                </Type.H1>
                <Type.Body className="body">{text}</Type.Body>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroImage>
  );
};

export default PageHero;
