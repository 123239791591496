import * as React from "react";
import { useRef } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Type } from "../Typography";
import { colors } from "../../../vars/palette";
import HubspotForm from "react-hubspot-form";

const HubspotContactForm = props => {
  const { region, portalId, formId } = props;
  React.useEffect(() => {
      const script = document.createElement('script');
      script.src='https://js.hsforms.net/forms/shell.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
          // @ts-ignore
          if (window.hbspt) {
              // @ts-ignore
              window.hbspt.forms.create({
                  region: region,
                  portalId: portalId,
                  formId: formId,
                  target: '#hubspotForm'
              })
          }
      });
  }, []);

  return (
      <div>
          <div id="hubspotForm"></div>
      </div>
  );
};

const Wrap = styled.div`
  * {
    color: ${colors.white.hex} !important;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  @media only screen and (max-width: 800px) {
    padding-bottom: 4rem !important;
    h5 {
      text-align: center !important;
    }
    form {
      left: -1rem;
    }
  }
`;
const formElementHeight = "40px";
const FormDiv = styled.div`
  background: ${colors.green.hex};
  h2,
  p {
    display: none;
  }
  form {
    position: relative;
    top: -0.5rem;
    margin-left: 3rem;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    background: ${colors.green.hex};
    .container-true {
      display: flex;
    }
    button {
      content: "Subscribe";
      background: ${colors.white.hex};
      color: ${colors.black.hex} !important;
      font-weight: 500;
      font-size: 0.79rem;
      border: none;
      border-radius: 0;
      position: relative;
      padding: 0 1rem;
      height: ${formElementHeight};
      :hover {
        background: ${colors.blue.hex};
        color: ${colors.white.hex} !important;
      }
    }
    input {
      border-radius: 0;
      border: 2px solid ${colors.white.hex};
      background: ${colors.green.hex};
      height: ${formElementHeight};
      ::placeholder {
        color: ${colors.green.hex};
      }
      :focus {
        border: 2px solid ${colors.white.hex};
        background: ${colors.green.hex};
        outline: 0;
      }
    }
    label {
      position: absolute;
      top: -15px;
      left: 3rem;
      background: ${colors.green.hex};
      padding: 0 5px 0 3px;
      text-transform: uppercase;
    }
    @media only screen and (max-width: 1199px) {
      margin-left: 0 !important;
      margin-top: 2rem;
    }
    @media only screen and (max-width: 800px) {
      form {
        left: -1rem;
      }
    }
  }
`;
const Divider = styled.div`
  width: 2px;
  height: 5rem;
  background: ${colors.white.hex};
  margin: 0 2rem;
  font-weight: 500;
  @media only screen and (max-width: 1199px) {
    background: ${colors.green.hex};
  }
  @media only screen and (max-width: 765px) {
    display: none;
  }
`;

const NewsletterSignupCTA = () => {
  const formRef = useRef();
  return (
    <Container fluid style={{ background: colors.green.hex }}>
      <Container>
        <Wrap>
          <Type.H6
            style={{
              textTransform: "none",
              fontSize: "1.5rem",
              fontWeight: 500,
              maxWidth: "15rem",
              textAlign: "right"
            }}>
            Stay Up-to-Date With DIPRA
          </Type.H6>
          <Divider />
          <Type.P
            style={{ maxWidth: "23rem", fontWeight: 500, fontSize: "1rem" }}>
            Subscribe to our e-newsletters for topical commentary, resources,
            services, and events.
          </Type.P>
          <FormDiv id="form-div" ref={formRef}>
          <HubspotContactForm  portalId={"21124615"} formId={"459628a1-1f63-4ce1-99c2-dacf1d70b295"} ></HubspotContactForm>
            {/* <HubspotForm
              portalId="21124615"
              formId="459628a1-1f63-4ce1-99c2-dacf1d70b295"
              onSubmit={() => console.log("Submitted!")}
              // onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            /> */}
            {/* <NetlifyForm
              title={`Newsletter Signup CTA`}
              config={{
                thankYou: `You are now subscribed!`,
                name: `newsletter_signup_cta`,
                postUrl: `/`,
                //consoleMessage: true,
                submit: `Subscribe`
              }}
              fields={[{ as: `email` }]}
            /> */}
          </FormDiv>
        </Wrap>
      </Container>
    </Container>
  );
};

export default NewsletterSignupCTA;
