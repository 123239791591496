import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import CardSlider from "../CardSlider/CardSlider";
import IconCard from "../RichText/Blocks/IconCard";
import { BREAKPOINT_LARGE_MAX, Type } from "../Typography";
import { colors } from "../../../vars/palette";
import { WmkLink } from "wmk-link";
import { BlockIconCardFields } from "../../../fragments/NodeRichTextFields";
import { MenuFields } from "../../../fragments/NodeMenuFields";

const Wrap = styled.div`
  margin: 3rem 0 0 0;
  @media only screen and (max-width: ${BREAKPOINT_LARGE_MAX}) {
    display: none !important;
  }
`;
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10%;
  a,
  button {
    margin: 0.5rem 1.5rem;
    color: ${colors.black.hex};
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    background: none;
    border: none;
    border-bottom: 5px solid rgba(0, 0, 0, 0);
    &.active {
      border-bottom: 5px solid ${colors.green.hex};
    }
    :hover {
      border-bottom: 5px solid ${colors.green.hex};
    }
  }
  a {
    &:hover {
      border-bottom: 0px;
    }
  }
`;

const HowCanWeHelpSlider = () => {
  const [currMenu, setCurrMenu] = useState("Ask an Expert");

  const data: {
    menu: MenuFields;
    cards: { edges: { node: BlockIconCardFields }[] };
  } = useStaticQuery(graphql`
    query {
      menu: contentfulMenu(title: { eq: "How Can We Help You Menu" }) {
        ...NodeMenuFields
      }
      cards: allContentfulBlockIconCard(
        filter: { blockId: { regex: "/How Can We Help/" } }
      ) {
        edges {
          node {
            ...NodeBlockIconCard
          }
        }
      }
    }
  `);

  const menuData = data.menu.links;
  const _edges = [...data.cards.edges];
  const cardData = _edges.map((p) => p.node);

  const _cards = cardData.map((card, i) => {
    return card.blockId.indexOf(currMenu) !== -1 ? (
      <IconCard
        key={card.blockId + i}
        icon={card.iconImage ? card.iconImage : card.iconImageNoSize}
        headline={card.headline}
        copy={card.copy}
        button={card.button}
        buttonLabel={card.buttonLabel}
      />
    ) : null;
  });
  _cards.reverse();
  const cards = _cards.filter((e) => e !== null);
  return (
    <Wrap>
      <Container>
        <Row>
          <Col>
            <Type.SecondaryTitle
              as="h2"
              style={{
                color: colors.blue.hex,
                textAlign: "center",
                textTransform: "none",
                marginBottom: "1rem"
              }}>
              How Can We Help You?
            </Type.SecondaryTitle>
            <ButtonsWrap>
              {menuData.map((menu, i) => {
                const type = menu.links ? "menu" : "link";
                const menuParent = menu?.menuParent;
                const menuTitle = menuParent?.pageTitle
                  ? menuParent?.pageTitle
                  : menuParent?.resourceTitle;
                const pageTitle =
                  menu?.pageTitle === "Subscribe"
                    ? "DIPRA E-Newsletters"
                    : menu?.pageTitle;
                const parentPath = menu?.parentPath;
                const JSX =
                  type === "menu" ? (
                    <button
                      key={menuTitle + i}
                      onClick={() => setCurrMenu(menuTitle)}
                      className={currMenu === menuTitle ? "active" : ""}>
                      {menuTitle}
                    </button>
                  ) : (
                    <button key={pageTitle + i}>
                      <WmkLink to={parentPath}>{pageTitle}</WmkLink>
                    </button>
                  );
                return JSX;
              })}
            </ButtonsWrap>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardSlider cards={cards} prefix="How Can We Help" />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default HowCanWeHelpSlider;
